import {
    Avatar,
    Box,
    BoxProps,
    CloseButton,
    Drawer,
    DrawerContent,
    Flex,
    FlexProps,
    HStack,
    Icon,
    IconButton,
    Image,
    Link,
    Menu,
    MenuButton,
    Select,
    Stack,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
import { Link as DefaultLink, useLocation, useNavigate } from 'react-router-dom';
import {
    FiHome,
    FiLogOut,
    FiMenu,
    FiSettings
} from 'react-icons/fi';
import React, { ReactNode, useEffect } from 'react';
import { getReferalProfile, getUser } from '../store/actions';
import { useAppDispatch, useAppSelector } from '../hooks';

import { BiTask } from 'react-icons/bi';
import CitationLogo from '../theme/assets/transferal_logo.png';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { TbFileInvoice } from 'react-icons/tb';
import { checkIfKeyExist } from '../utils';
import { inject } from '@vercel/analytics';
import { logoutSuccess } from '../store/reducers';

interface LinkItemProps {
    name: string;
    route?: string;
    icon: IconType;
}
const LinkItems: Array<LinkItemProps> = [
    { name: 'Home', route: '/', icon: FiHome },
    { name: 'Invoices', route: '/invoices', icon: TbFileInvoice },
    { name: 'Tasks', route: '/tasks', icon: BiTask },
    { name: 'Settings', route: '/settings', icon: FiSettings },
];

export default function AppContainer({
    children,
}: {
    children: ReactNode;
}) {
    
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { isAuthenticated } = useAppSelector(state => state.authReducer);
    const { user } = useAppSelector(state => state.userReducer);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();

    inject();

    const validate = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgot-password' || location.pathname === '/reset-password';

    useEffect(() => {
        if (checkIfKeyExist(user, 'user_id') === false && isAuthenticated) {
            dispatch(getUser())
                .then(() => dispatch(getReferalProfile()))
        }
    }, []);

    useEffect(() => {
        if (!isAuthenticated) return navigate('/login', { replace: true });
    }, [isAuthenticated]);

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            {isAuthenticated &&
                <SidebarContent
                    onClose={() => onClose}
                    display={{ base: 'none', md: 'block' }}
                />}
            {isAuthenticated &&
                <Drawer
                    autoFocus={false}
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full">
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>}
            {/* mobilenav */}
            {isAuthenticated && <MobileNav onOpen={onOpen} onClose={onClose} />}
            <Box ml={{ base: 0, md: isAuthenticated && 60 }} p={isAuthenticated ? '4' : '0'} pt={validate ? 0 : 24}>
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {

    const location: any = useLocation();
    // const { user } = useAppSelector(state => state.userReducer);
    const dispatch = useAppDispatch();

    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('gray.100', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.100', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            style={{
                overflow: 'auto'
            }}
            {...rest}>
            <Flex alignItems="center" mx="8" justifyContent="space-between">
                <Stack alignItems={'center'} my={5}>
                    <Image
                        alt={'logo'}
                        src={CitationLogo}
                    />
                </Stack>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            <Select
                placeholder='Trucklivery Mover'
                focusBorderColor='#4caf50'
                borderColor={'#4caf50'}
                maxW={'200px'}
                ml={4}
                my={3}
            >
                <option value='option1'>Trucklivery Mover</option>
            </Select>
            {
                // user?.company_id &&
                <>
                    {
                        LinkItems.map((link) => (
                            <NavItem
                                key={link.name}
                                fontWeight={'medium'}
                                color={location?.pathname === link?.route ? 'green.500' : 'inherit'}
                                icon={link.icon}
                                to={link.route}
                                onClose={onClose}
                                _hover={{
                                    backgroundColor: 'green.100',
                                    color: 'inherit'
                                }}
                            >
                                {link.name}
                            </NavItem>
                        ))
                    }
                    <NavItem
                        fontWeight={'medium'}
                        icon={FiLogOut}
                        onClose={onClose}
                        _hover={{
                            backgroundColor: 'green.100',
                            color: 'inherit'
                        }}
                        onClick={() => {
                            dispatch(logoutSuccess());
                        }}
                    >
                        Logout
                    </NavItem>
                </>
            }
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon?: IconType;
    children: ReactText;
    to?: string | any;
    onClose: () => void;
}
const NavItem = ({ icon, children, to, onClose, ...rest }: NavItemProps) => {
    return (
        <Link href="#" style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={DefaultLink} to={to} onClick={onClose}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: '#eeeeee',
                    color: '#212121',
                }}
                {...rest}>
                {icon ? (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: '#212121',
                        }}
                        as={icon}
                    />
                ) : <></>}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
    onClose: () => void;
}
//eslint-disable-next-line
const MobileNav = ({ onOpen, onClose, ...rest }: MobileProps) => {
    const { user } = useAppSelector(state => state.userReducer);
    // const dispatch: AppDispatch = useAppDispatch();

    useEffect(() => {
        // dispatch(companyExistance());
    }, []);

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('gray.100', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.100', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            position={'fixed'}
            style={{
                width: '-webkit-fill-available',
                minWidth: '-moz-available'
            }}
            zIndex={4}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="md" textTransform='capitalize' fontWeight={'semibold'}>Hello, {user?.first_name || 'Name'} {user?.last_name || 'Surname'}</Text>
                                    {/* <Text fontSize="xs" color="gray.600" textTransform='capitalize'>
                      {user?.role || 'Role'}
                    </Text> */}
                                </VStack>
                                <Avatar
                                    size={'sm'}
                                    name={`${user?.first_name} ${user?.last_name}`}
                                    src={user?.img_url}
                                />
                            </HStack>
                        </MenuButton>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};