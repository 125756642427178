import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { inputAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    field: {
        border: '1px solid',
        borderColor: 'green.500',
        background: 'green.50'
    },
})

export const inputTheme = defineMultiStyleConfig({ baseStyle });