import { AppDispatch, RootState } from '..';
import { REACT_APP_MANAGE_SERVICE_ENDPOINT, headers } from '.';
import { setDeliveriesSuccess, setLoadingDeliveries } from '../reducers';

import axios from 'axios';
import { deliveryType } from '../../types';

export const fetchDeliveries = () => async (dispatch: AppDispatch, getState: RootState) => {

    const { token } = getState().authReducer;

    const { profile } = getState().referalProfileReducer;
    const { referer_profile_id } = profile ?? {};

    dispatch(setLoadingDeliveries(true));

    try {

        const { data } = await axios.get(`${REACT_APP_MANAGE_SERVICE_ENDPOINT}/deliveries/referer/${referer_profile_id}`, headers(token));

        let { deliveries }: { deliveries: Array<deliveryType>} = data;
        deliveries = deliveries?.reverse();
        dispatch(setDeliveriesSuccess(deliveries));
        dispatch(setLoadingDeliveries(false));
        
    } catch (error) {

        console.error(error);
        dispatch(setLoadingDeliveries(false));

    }
    
};