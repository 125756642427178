import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { deliveryType } from "../../types";

type initialStaateType = {
    is_loading_deliveries: boolean;
    deliveries: deliveryType [];
};

const initialState: initialStaateType = {
    is_loading_deliveries: false,
    deliveries: []
};

const deliverySlice = createSlice({
    name: 'deliveries',
    initialState,
    reducers: {
        setDeliveriesSuccess: (state, action: PayloadAction<deliveryType []>) => {
            return {
                ...state,
                deliveries: action.payload,
            };
        },
        setLoadingDeliveries: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                is_loading_deliveries: action.payload
            };
        }
    }
});

export const { setDeliveriesSuccess, setLoadingDeliveries } = deliverySlice.actions;

export default deliverySlice.reducer;