import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    PinInput,
    PinInputField,
    Stack,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import { Link as DefaultLink, useNavigate } from 'react-router-dom';
import { FiArrowLeft, FiEye, FiEyeOff } from 'react-icons/fi';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { AppDispatch } from '../../store';
import { ToastContainer } from 'react-toastify';
import { resetPasswordFormTypes } from '../../types';
import { resetPasswordFormValidation } from '../../utils';
import { resetUserPassword } from '../../store/actions';
import { useFormik } from 'formik';

export const ResetPassword = () => {

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const handlePasswordClick = () => setShowPassword(!showPassword);
    const handleConfirmPasswordClick = () => setShowConfirmPassword(!showConfirmPassword);

    const { is_reseting_passsword } = useAppSelector(state => state.authReducer);

    const dispatch: AppDispatch = useAppDispatch();

    const navigate = useNavigate();

    const resetPasswordForm = useFormik({
        initialValues: {
            otp: '',
            password: '',
            confirm_password: '',
        },
        validationSchema: resetPasswordFormValidation,
        validateOnBlur: true,
        onSubmit: (values: resetPasswordFormTypes) => {
            dispatch(resetUserPassword(values)).then(() => navigate('/login'));
        },
    });

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Reset password?</Heading>
                    <Text fontSize={'lg'} color={'gray.600'} textAlign='center'>
                        Enter  OTP, to reset your password.
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <HStack align={'center'}>
                            <Stack>
                                <IconButton aria-label='go back' icon={<FiArrowLeft style={{ width: '20px', height: '20px' }} />} as={DefaultLink} to='/forgot-password' />
                            </Stack>
                            <Stack>
                                <Text display={'flex'} fontSize={'md'} fontWeight='medium' textAlign={'center'}>
                                    Go back - Reset Password
                                </Text>
                            </Stack>
                        </HStack>
                        <Divider />
                        <form onSubmit={resetPasswordForm.handleSubmit}>
                            <FormControl id='otp' mb={4}>
                                <FormLabel>OTP</FormLabel>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <PinInput focusBorderColor='#4caf50' type='alphanumeric' onChange={(value) => resetPasswordForm.setFieldValue('otp', value)}>
                                        <PinInputField mr={2} onBlur={resetPasswordForm.handleBlur} />
                                        <PinInputField mr={2} onBlur={resetPasswordForm.handleBlur} />
                                        <PinInputField mr={2} onBlur={resetPasswordForm.handleBlur} />
                                        <PinInputField mr={2} onBlur={resetPasswordForm.handleBlur} />
                                        <PinInputField mr={2} onBlur={resetPasswordForm.handleBlur} />
                                        <PinInputField />
                                    </PinInput>
                                </div>
                                {resetPasswordForm.errors.otp && <Text fontSize='sm' color='red.400'>{resetPasswordForm.errors.otp}</Text>}
                            </FormControl>
                            <FormControl id='password' mb={4}>
                                <FormLabel>Password</FormLabel>
                                <InputGroup size='md'>
                                    <Input focusBorderColor='#4caf50' type={showPassword ? 'text' : 'password'} name='password' value={resetPasswordForm.values.password} onChange={resetPasswordForm.handleChange} onBlur={resetPasswordForm.handleBlur} />
                                    <InputRightElement >
                                        <IconButton aria-label='go back' onClick={handlePasswordClick} icon={showPassword ? <FiEyeOff style={{ width: '20px', height: '20px' }} /> : <FiEye style={{ width: '20px', height: '20px' }} />} />
                                    </InputRightElement>
                                </InputGroup>
                                {resetPasswordForm.errors.password && <Text fontSize='sm' color='red.400'>{resetPasswordForm.errors.password}</Text>}
                            </FormControl>
                            <FormControl id='confirm_password' mb={4}>
                                <FormLabel>Confrim Password</FormLabel>
                                <InputGroup size='md'>
                                    <Input focusBorderColor='#4caf50' type={showConfirmPassword ? 'text' : 'password'} name='confirm_password' value={resetPasswordForm.values.confirm_password} onChange={resetPasswordForm.handleChange} onBlur={resetPasswordForm.handleBlur} />
                                    <InputRightElement >
                                        <IconButton aria-label='go back' onClick={handleConfirmPasswordClick} icon={showConfirmPassword ? <FiEyeOff style={{ width: '20px', height: '20px' }} /> : <FiEye style={{ width: '20px', height: '20px' }} />} />
                                    </InputRightElement>
                                </InputGroup>
                                {resetPasswordForm.errors.confirm_password && <Text fontSize='sm' color='red.400'>{resetPasswordForm.errors.confirm_password}</Text>}
                            </FormControl>
                            <Stack spacing={10}>
                                <Button
                                    type='submit'
                                    isLoading={is_reseting_passsword}
                                    disabled={!resetPasswordForm.isValid || !resetPasswordForm.values.password || !resetPasswordForm.values.otp || is_reseting_passsword}
                                    colorScheme={'green'}
                                    >
                                    Reset Password
                                </Button>
                            </Stack>
                        </form>
                    </Stack>
                </Box>
            </Stack>
            <ToastContainer />
        </Flex>
    );
};