import { Badge, Button, HStack, Heading, SimpleGrid, Skeleton, Stack, Table, TableContainer, Tag, TagLabel, TagLeftIcon, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { BiMoneyWithdraw, BiTask } from 'react-icons/bi';
import { FiCheck, FiMinus, FiX } from 'react-icons/fi';
import React, { useEffect } from 'react';
import { fetchDeliveries, getReferalProfile } from '../../store/actions';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { TbFileInvoice } from 'react-icons/tb';
import { deliveryType } from '../../types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const Home = () => {

    const { profile } = useAppSelector(state => state.referalProfileReducer);

    const { deliveries, is_loading_deliveries } = useAppSelector(state => state.deliveriesReducer);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const getPotentialTotalAmount = () => {
        const _deliveries = deliveries.filter((delivery: deliveryType) => delivery.referer?.referal_invoice_status !== 'cancelled');
        const sum = _deliveries.reduce(
            (accumulator: any, currentValue: deliveryType) => accumulator + currentValue?.referer?.commission,
            0,
        );
        return sum;
    };

    const getAccumulatedTotalAmount = () => {
        const _deliveries = deliveries.filter((delivery: deliveryType) => delivery.referer?.referal_invoice_status === 'paid');
        const sum = _deliveries.reduce(
            (accumulator: any, currentValue: deliveryType) => accumulator + currentValue?.referer?.commission,
            0,
        );
        return sum;
    };

    useEffect(() => {
        dispatch(getReferalProfile());
        dispatch(fetchDeliveries());

        return () => {
            if(profile === null) dispatch(getReferalProfile()).then(() => dispatch(fetchDeliveries()));
        }
    }, []);

    return (
        <>
            <Stack>
                <SimpleGrid columns={[1, 1, 3, 3, 3]} spacing={4}>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <BiMoneyWithdraw color='#4caf50' size={'32px'} />
                        <Text color={'gray.500'}>Potential Total Amount</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>R{Number(getPotentialTotalAmount())?.toFixed(2)}</Text>
                    </Stack>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <TbFileInvoice color='#4caf50' size={'32px'} />
                        <Text color={'gray.500'}>All Invoices</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{deliveries?.length}</Text>
                    </Stack>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <BiTask color='#4caf50' size={'32px'} />
                        <Text color={'gray.500'}>All Tasks</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{deliveries?.length}</Text>
                    </Stack>
                </SimpleGrid>
                <SimpleGrid pt={2} columns={[1, 1, 2, 2, 2]} spacing={4}>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <HStack justifyContent={'space-between'}>
                            <Heading size={'sm'} fontWeight={'semibold'}>Invoices</Heading>
                            <Button size={'xs'} colorScheme={'green'} variant={'outline'} onClick={() => navigate('/invoices')}>View All Invoices</Button>
                        </HStack>
                        <TableContainer pt={3}>
                            <Table variant={'simple'} size={'md'}>
                                <Thead>
                                    <Tr>
                                        <Th>Invoice</Th>
                                        <Th>Status</Th>
                                        <Th>Commission (R)</Th>
                                        <Th textAlign={'end'}>Created At</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        deliveries?.slice(0, 3)?.map((delivery: deliveryType, index: number) => (
                                            <Tr key={index}>
                                                <Td fontWeight={'medium'}>{delivery?.referer?.referal_invoice_id ? delivery?.referer?.referal_invoice_id : '-'}</Td>
                                                <Td>
                                                    <Tag size={'sm'} variant='subtle' colorScheme={delivery?.referer?.referal_invoice_status === 'paid' ? 'green' : delivery?.referer?.referal_invoice_status === 'cancelled' ? 'red' : 'gray'}>
                                                        <TagLeftIcon boxSize='12px' as={delivery?.referer?.referal_invoice_status === 'paid' ? FiCheck : delivery?.referer?.referal_invoice_status === 'cancelled' ? FiX : FiMinus} />
                                                        <TagLabel textTransform={'capitalize'}>{delivery?.referer?.referal_invoice_status}</TagLabel>
                                                    </Tag>
                                                </Td>
                                                <Td color={'green'} fontWeight={'semibold'}>R{Number(delivery?.referer?.commission)?.toFixed(2)}</Td>
                                                <Td>{moment(delivery?.createdAt).format('DD MMM, YYYY HH:mm')}</Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                            {
                                !deliveries.length &&
                                <Stack alignItems={'center'}>
                                    <TbFileInvoice fontSize={'100px'} color={'#9e9e9e'} />
                                    <Text color={'gray.500'}>No tasks available.</Text>
                                </Stack>
                            }
                            {
                                is_loading_deliveries &&
                                <Stack>
                                    <Skeleton height='50px' w={'auto'} />
                                </Stack>
                            }
                        </TableContainer>
                    </Stack>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <Heading size={'sm'} fontWeight={'semibold'}>My Card</Heading>
                        <HStack justifyContent={'center'} py={3}>
                            <Stack
                                p={4}
                                bgGradient={'linear(to-r, #1b5e20, #263238)'}
                                maxW={'350px'}
                                minW={'300px'}
                                minH={'150px'}
                                borderRadius={6}
                                color={'white'}
                                justifyContent={'space-between'}
                            >
                                <HStack>
                                    <Stack>
                                        <Text fontSize={'sm'}>Current Balance Accumulated</Text>
                                        <Text mt={'0px !important'} fontSize={'lg'} fontWeight={'bold'}>R{Number(getAccumulatedTotalAmount())?.toFixed(2)}</Text>
                                    </Stack>
                                </HStack>
                                <HStack justifyContent={'space-between'}>
                                    <Text fontSize={'sm'} fontStyle={!profile?.banking_details?.bank_name ? 'italic' : 'normal'}>{profile?.banking_details?.account_number ?? 'Account number'}</Text>
                                    <Text fontSize={'sm'} textTransform={'capitalize'} fontStyle={!profile?.banking_details?.bank_name ? 'italic' : 'normal'}>{profile?.banking_details?.bank_name ?? 'Bank name'}</Text>
                                </HStack>
                            </Stack>
                        </HStack>
                        <Button alignSelf={'center'} colorScheme={'green'} maxW={'350px'} size={'md'} onClick={() => navigate('/settings')}>Manage Bank Account </Button>
                    </Stack>
                </SimpleGrid>
                <SimpleGrid pt={2} columns={[1]}>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <HStack justifyContent={'space-between'}>
                            <Heading size={'sm'} fontWeight={'semibold'}>Tasks</Heading>
                            <Button size={'xs'} colorScheme={'green'} variant={'outline'} onClick={() => navigate('/tasks')}>View All Tasks</Button>
                        </HStack>
                        <TableContainer pt={3}>
                            <Table variant={'simple'} size={'md'}>
                                <Thead>
                                    <Tr>
                                        <Th>Delivery</Th>
                                        <Th>Invoice</Th>
                                        <Th>Status</Th>
                                        <Th>Total (R)</Th>
                                        <Th>Commission (R)</Th>
                                        <Th>Date</Th>
                                        <Th>Pickup address</Th>
                                        <Th>Dropoff address</Th>
                                        <Th>Fullname</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        deliveries?.slice(0, 5)?.map((delivery: deliveryType, index: number) => (
                                            <Tr key={index}>
                                                <Td fontWeight={'medium'}>{delivery?.delivery_id}</Td>
                                                <Td>{delivery?.referer?.referal_invoice_id ? delivery?.referer?.referal_invoice_id : '-'}</Td>
                                                <Td>
                                                    <Badge>{delivery?.status}</Badge>
                                                </Td>
                                                <Td color={'green.500'} fontWeight={'semibold'}>R{Number(delivery?.total)?.toFixed(2)}</Td>
                                                <Td color={'green.500'} fontWeight={'semibold'}>R{Number(delivery?.referer?.commission)?.toFixed(2)}</Td>
                                                <Td>{moment(delivery?.date).format('DD MMM, YYYY h:m')}</Td>
                                                <Td>{delivery?.from?.address_from}</Td>
                                                <Td>{delivery?.to?.address_to}</Td>
                                                <Td textTransform={'capitalize'}>{delivery?.customer_name}</Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                            {
                                !deliveries.length &&
                                <Stack alignItems={'center'}>
                                    <BiTask fontSize={'100px'} color={'#9e9e9e'} />
                                    <Text color={'gray.500'}>No tasks available.</Text>
                                </Stack>
                            }
                            {
                                is_loading_deliveries &&
                                <Stack>
                                    <Skeleton height='50px' w={'auto'} />
                                </Stack>
                            }
                        </TableContainer>
                    </Stack>
                </SimpleGrid>
            </Stack>
        </>
    )
};