import { FleetType, Fleets } from "../enums";

export const checkIfKeyExist = (objectName: object, keyName: string) => {
    let keyExist = Object.keys(objectName).some(key => key === keyName);
    return keyExist;
};

export const getFleetImage = (fleet: string) => {
    switch (fleet) {
        case FleetType.BICYCLE:
            return Fleets?.BICYCLE;
        case FleetType.SCOOTER:
            return Fleets?.SCOOTER;
        case FleetType.MINI_BAKKIE:
            return Fleets?.MINI_BAKKIE;
        case FleetType.BAKKIE:
            return Fleets?.BAKKIE;
        case FleetType.HALF_TRUCK:
            return Fleets?.HALF_TRUCK;
        case FleetType.FOUR_TONS_TRUCK:
            return Fleets?.FOUR_TONS_TRUCK;
        case FleetType.EIGHT_TONS_TRUCK:
            return Fleets?.EIGHT_TONS_TRUCK;
    }
};