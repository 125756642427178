import { AppDispatch, RootState } from '..';
import { REACT_APP_COMPANY_ID, REACT_APP_COMPANY_SERVICE_ENDPOINT } from '.';
import { getCompanySuccess, isLoadingCompany } from '../reducers';

import axios from 'axios';

export const getCompany = () => async (dispatch: AppDispatch, getState: RootState) => {

    dispatch(isLoadingCompany(true));

    try {
        
        const { data } = await axios.get(`${REACT_APP_COMPANY_SERVICE_ENDPOINT}/company/website/${REACT_APP_COMPANY_ID}`);
        const { company } = data;

        dispatch(getCompanySuccess(company));
        dispatch(isLoadingCompany(false));
        
    } catch (error) {
        dispatch(isLoadingCompany(false));
        console.error(error);
    }
    
};