import { Button, Divider, FormControl, FormHelperText, FormLabel, HStack, Heading, Input, SimpleGrid, Stack, Text } from '@chakra-ui/react';

import { BankingForm } from './forms';
import React from 'react';

export const Settings = () => {
    return (
        <>
            <Stack>
                <Heading size={'lg'}>Settings</Heading>
                <SimpleGrid pt={6} columns={[1, 1, 2, 2, 2]} spacing={4}>
                    <Stack bgColor={'white'} borderRadius={4}>
                        <Stack p={4}>
                            <Text>Personal Information</Text>
                        </Stack>
                        <Divider mt={'0px !important'} />
                        <Stack p={4}>
                            <form>
                                <SimpleGrid columns={[1, 2, 2, 2, 2]} spacing={3}>
                                    <FormControl isRequired isDisabled>
                                        <FormLabel>First name</FormLabel>
                                        <Input
                                            type={'text'}
                                            placeholder='John'
                                            focusBorderColor='#4caf50'
                                        />
                                        <FormHelperText color={'yellow.500'}>First name can't be edited.</FormHelperText>
                                    </FormControl>
                                    <FormControl isRequired isDisabled>
                                        <FormLabel>Last name</FormLabel>
                                        <Input
                                            type={'text'}
                                            placeholder='Doe'
                                            focusBorderColor='#4caf50'
                                        />
                                        <FormHelperText color={'yellow.500'}>Last name can't be edited.</FormHelperText>
                                    </FormControl>
                                </SimpleGrid>
                                <SimpleGrid mt={4} columns={[1, 2, 2, 2, 2]} spacing={3}>
                                    <FormControl isRequired>
                                        <FormLabel>Phone</FormLabel>
                                        <Input
                                            type={'text'}
                                            placeholder='0123456789'
                                            focusBorderColor='#4caf50'
                                        />
                                    </FormControl>
                                    <FormControl isRequired isDisabled>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            type={'text'}
                                            placeholder='john@doe.com'
                                            focusBorderColor='#4caf50'
                                        />
                                        <FormHelperText color={'yellow.500'}>Email address can't be edited.</FormHelperText>
                                    </FormControl>
                                </SimpleGrid>
                                <HStack mt={6} justifyContent={'flex-end'}>
                                    <Button
                                        colorScheme={'green'}
                                        variant={'outline'}
                                        minW={'120px'}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        colorScheme={'green'}
                                        type={'submit'}
                                        minW={'120px'}
                                    >
                                        Save
                                    </Button>
                                </HStack>
                            </form>
                        </Stack>
                    </Stack>
                    <BankingForm />
                    {/* <PassWordUpdateChange /> */}
                </SimpleGrid>
            </Stack>
        </>
    );
};