import * as YUP from 'yup';

declare module 'yup' {
    interface StringSchema {
        integer(): StringSchema;
        letters(): StringSchema;
        startWith0(): StringSchema;
    }
}

YUP.addMethod<YUP.StringSchema>(YUP.string, 'integer', function () {
    return this.matches(/^\d+$/, 'Enter numbers/integers only.');
});

YUP.addMethod<YUP.StringSchema>(YUP.string, 'letters', function letters() {
    return this.matches(/^[A-Za-z]+$/, 'Enter letters only.');
});

YUP.addMethod<YUP.StringSchema>(YUP.string, 'startWith0', function () {
    return this.matches(/^0/, 'Should start with 0.');
});

export const loginFormValidation = YUP.object().shape({
    email: YUP.string()
        .email('Invalid email address.')
        .required('Enter email address.'),
    password: YUP.string()
        .required('Enter password.'),
});

export const forgotPasswordFormValidation = YUP.object().shape({
    email: YUP.string()
        .email('Invalid email address.')
        .required('Enter email address.'),
});

export const resetPasswordFormValidation = YUP.object().shape({
    otp: YUP.string()
        .min(6, 'OTP is too short.')
        .max(6, 'OTP is too long.')
        .required('Enter OTP.'),
    password: YUP.string()
        .min(6, 'At least 6 characters, password too short.')
        .required('Enter password.'),
    confirm_password: YUP.string()
        .oneOf([YUP.ref('password'), null], 'Passwords must match.')
});

export const registerFormValidation = YUP.object().shape({
    first_name: YUP.string()
        .min(2, 'First name too short.')
        .max(20, 'First name too long.')
        .required('Enter first name.'),
    last_name: YUP.string()
        .min(2, 'Last name too short.')
        .max(20, 'Last name too long.')
        .letters()
        .required('Enter last name.'),
    email: YUP.string()
        .email('Invalid email address.')
        .required('Enter email address.'),
    password: YUP.string()
        .min(5, 'Password too short.')
        .required('Enter password.'),
    phone: YUP.string()
        .integer()
        .startWith0()
        .min(10, 'Phone too short.')
        .max(10, 'Phone too long.')
        .required('Enter phone number.'),
    gender: YUP.string()
        .required('Select gender.'),
    role: YUP.string()
        .required('Select role.')
});

export const bankingFormValidation = YUP.object().shape({
    bank_name: YUP.string()
        .required('Select bank name.'),
    account_type: YUP.string()
        .required('Select account type.'),
    account_number: YUP.string()
        .integer()
        .required('Enter account number.'),
});

export const updatePasswordFormValidation = YUP.object().shape({
    password: YUP.string()
        .min(6, 'Password must be at least 6 characters long.')
        .required('Enter password.'),
    confirm_password: YUP.string()
        .oneOf([YUP.ref('password'), null], 'Passwords must match')
});

export const addCustomerFormValidation = YUP.object().shape({
    first_name: YUP.string()
        .min(2, 'Customer name too short.')
        .required('Enter first name.'),
    last_name: YUP.string()
        .min(3, 'Customer name too short.')
        .required('Enter last name.'),
    email: YUP.string()
        .email('Invalid email address.')
        .required('Enter email address.'),
    phone: YUP.string()
        .integer()
        .startWith0()
        .min(10, 'Phone number too short.')
        .max(10, 'Phone number too long.')
        .required('Enter phone number.'),
    company_id: YUP.string()
        .required('Company ID is required.'),
    company_name: YUP.string()
        .required('Company name required.'),
    company_email: YUP.string()
        .required('Company email required.')
});

export const addDeliveryFormValidation = YUP.object().shape({
    payment_method: YUP.string()
        .required('Select payment method.'),
    date: YUP.string()
        .required('Select date.'),
    time: YUP.string()
        .required('Select time.'),
    loaders: YUP.string()
        .integer()
        .required('Select number of loaders/helpers.'),
    description: YUP.string()
        .required('Provide description'),
    company_id: YUP.string()
        .required('Company ID is required.')
});
