import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { userType } from '../../types';

const initialState: userType = {
    user: {},
    is_updating_user: false,
    is_updating_password: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userSuccess: (_, action: PayloadAction<object>) => {
            const { user }: any = action.payload;
            return {
                user: user,
            };
        },
        onUpdatingUser: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                is_updating_user: action.payload
            };
        },
        onUpdatingPassword: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                is_updating_password: action.payload
            };
        }
    }
});

export const { userSuccess, onUpdatingUser, onUpdatingPassword } = userSlice.actions;

export default userSlice.reducer;