import { useAppSelector } from '../hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = (props: { component: JSX.Element }) => {

  const navigate = useNavigate();

  const { isAuthenticated, token } = useAppSelector(state => state.authReducer);

  useEffect(() => {
    if (!isAuthenticated || !token || token == null) {
      navigate('/login');
    }
  }, [isAuthenticated, token]);

  return isAuthenticated && token && props.component;

};