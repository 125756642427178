import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fleetType } from '../../types';

const initialState: any = {
    fleets: [],
    fleets_size: ['bicycle', 'scooter', 'mini bakkie', 'bakkie', 'half truck', '4ton', '8ton', 'van'],
    is_loading_fleets: false,
};

const fleetSlice = createSlice({
    name: 'fleet',
    initialState,
    reducers: {
        getFleetsSuccess: (state, actions: PayloadAction<fleetType[]>) => {
            return {
                ...state,
                fleets: actions.payload
            };
        },
        onLoadingFleets: (state, actions: PayloadAction<boolean>) => {
            return {
                ...state,
                is_loading_fleets: actions.payload
            };
        },
    }
});

export const { getFleetsSuccess, onLoadingFleets } = fleetSlice.actions;

export default fleetSlice.reducer;