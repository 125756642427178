import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import { Link as DefaultLink, useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { AppDispatch } from '../../store';
import { ToastContainer } from 'react-toastify';
import { login } from '../../store/actions';
import { loginFormTypes } from '../../types';
import { loginFormValidation } from '../../utils';
import { useFormik } from 'formik';

export const Login = () => {

  const toast = useToast();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { isAuthenticated, message } = useAppSelector(state => state.authReducer);
  const dispatch: AppDispatch = useAppDispatch();

  const navigate = useNavigate();

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginFormValidation,
    validateOnBlur: true,
    onSubmit: (values: loginFormTypes) => {
      dispatch(login(values,
        () =>
          toast({
            title: 'Failed to login.',
            description: 'Email is invalid.',
            status: 'error',
            duration: 9000,
            position: 'top-right',
            isClosable: true,
          }),
        () =>
          toast({
            title: 'Failed to login.',
            description: 'Invalid password.',
            status: 'error',
            duration: 9000,
            position: 'top-right',
            isClosable: true,
          })
      ))
        .then(() => {
          loginForm.setSubmitting(false);
          // navigate('/', { replace: true });
        })
        .catch(() => loginForm.setSubmitting(false));
    },
  });

  const handleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (isAuthenticated) navigate('/', { replace: true });
  }, [isAuthenticated, message]);

  return (
    <>
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack mb={4}>
              <Heading fontSize={'4xl'}>Sign In</Heading>
              <Text fontSize={14} color={'gray.500'}>
                Enter your email and password to sign in!
              </Text>
            </Stack>
            <Stack spacing={4}>
              <form onSubmit={loginForm.handleSubmit}>
                <FormControl id='email' mb={4} isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type='email'
                    name='email'
                    defaultValue={'referer2@trucklivery.com'}
                    placeholder='john@doe.com'
                    focusBorderColor='#4caf50'
                    value={loginForm.values.email}
                    onChange={loginForm.handleChange}
                    onBlur={loginForm.handleBlur}
                  />
                  {loginForm.errors.email && <Text fontSize='sm' color='red.400'>{loginForm.errors.email}</Text>}
                </FormControl>
                <FormControl id='password' mb={4} isRequired>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      defaultValue={'password'}
                      placeholder='**********'
                      focusBorderColor='#4caf50'
                      value={loginForm.values.password}
                      onChange={loginForm.handleChange}
                      onBlur={loginForm.handleBlur}
                    />
                    <InputRightElement>{showPassword ? <FiEyeOff size={18} onClick={handleShowPassword} /> : <FiEye size={18} onClick={handleShowPassword} />}</InputRightElement>
                  </InputGroup>
                  {loginForm.errors.password && <Text fontSize='sm' color='red.400'>{loginForm.errors.password}</Text>}
                </FormControl>
                <Stack>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    align={'start'}
                    mb={3}
                    justify={'space-between'}>
                    <Link color={'green.500'} fontWeight={'medium'} as={DefaultLink} to={'/forgot-password'}>Forgot password?</Link>
                  </Stack>
                  <Button
                    type='submit'
                    isLoading={loginForm.isSubmitting}
                    disabled={loginForm.isSubmitting || !loginForm.isValid}
                    colorScheme={'green'}
                    >
                    Sign in
                  </Button>
                </Stack>
              </form>
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Not registered yet? <Link as={DefaultLink} color={'green.500'} fontWeight={'medium'} to='/register'>Register</Link>
              </Text>
            </Stack>
          </Box>
        </Stack>
        <ToastContainer />
      </Flex>
    </>
  );
};