import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { companyType } from '../../types';

type initialStateType = {
    company: companyType;
    is_loading_company: boolean;
};

const initialState: initialStateType = {
    company: {},
    is_loading_company: false,
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        getCompanySuccess: (state, action: PayloadAction<companyType>) => {
            return {
                ...state,
                company: action.payload,
            };
        },
        isLoadingCompany: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                is_loading_company: action.payload,
            };
        }
    }
});

export const { getCompanySuccess, isLoadingCompany } = companySlice.actions;

export default companySlice.reducer;