import { persistReducer, persistStore } from 'redux-persist';

import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducers';
import storage from 'redux-persist/lib/storage';

// import thunk from 'redux-thunk';
// import logger from 'redux-logger';

const persistConfig: any = {
  key: 'root',
  storage,
  whitelist: ['authReducer']
};

const persistedReducer: any = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;