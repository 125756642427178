export * from './auth-actions';
export * from './referal-profile-actions';
export * from './vehicle-actions';  
export * from './fleet-actions';
export * from './company-actions';
export * from './deliveries-actions';

export const headers = (token: string) => ({
    headers: {
        authorization: `Bearer ${token}`
    }
});

export const { REACT_APP_AUTH_SERVICE_ENDPOINT, REACT_APP_COMPANY_SERVICE_ENDPOINT, REACT_APP_MANAGE_SERVICE_ENDPOINT, REACT_APP_COMPANY_ID } = process.env;