import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { refererProfileType } from "../../types";

type initialStateType = {
    is_loading_profile: boolean;
    profile: refererProfileType | object;
};

const initialState: initialStateType = {
    is_loading_profile: false,
    profile: {},
};

export const referalProfileSlice = createSlice({
    name: 'referalProfile',
    initialState,
    reducers: {
        referalProfileSuccess: (state, action: PayloadAction<refererProfileType>) => {
            return {
                ...state,
                profile: action.payload,
            };
        },
    }
});

export const { referalProfileSuccess } = referalProfileSlice.actions;

export default referalProfileSlice.reducer;