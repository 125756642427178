import { Button, Divider, FormControl, FormLabel, HStack, Input, Select, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { getReferalProfile, updateBankingDetails } from '../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import { bankingDetailsType } from '../../../types';
import { bankingFormValidation } from '../../../utils';
import { useFormik } from 'formik';

export const BankingForm = () => {

    const toast = useToast();

    const  { profile } = useAppSelector(state => state.referalProfileReducer);
    const { bank_name, account_type, account_number } = profile?.banking_details ?? {};

    const dispatch = useAppDispatch();

    const bankingForm = useFormik({
        initialValues: {
            bank_name: '',
            account_type: '',
            account_number: ''
        },
        validationSchema: bankingFormValidation,
        onSubmit: (values: bankingDetailsType) => {
            dispatch(updateBankingDetails(values))
                .then(() => {
                    toast({
                        title: 'Success.',
                        description: 'Banking details updated succefully.',
                        status: 'success',
                        position: 'top-right',
                        duration: 9000,
                        isClosable: true,
                    });
                })
                .catch(() => {
                    toast({
                        title: 'Error.',
                        description: 'Banking details not updated succefully.',
                        status: 'error',
                        position: 'top-right',
                        duration: 9000,
                        isClosable: true,
                    });
                })
                .finally(() => bankingForm.setSubmitting(false))
        }
    });

    const setBankingFormFields = () => {
        bankingForm.setFieldValue('bank_name', bank_name);
        bankingForm.setFieldValue('account_type', account_type);
        bankingForm.setFieldValue('account_number', account_number);
    };

    useEffect(() => {
        setBankingFormFields();
        return () => {
            if(profile === null) dispatch(getReferalProfile());
        }
    }, []);

    return (
        <>
            <Stack bgColor={'white'} borderRadius={4} h={'fit-content'}>
                <Stack p={4}>
                    <Text>Banking Information</Text>
                </Stack>
                <Divider mt={'0px !important'} />
                <Stack p={4}>
                    <form onSubmit={bankingForm.handleSubmit}>
                        <SimpleGrid columns={[1, 2, 2, 2, 2]} spacing={3}>
                            <FormControl isRequired>
                                <FormLabel>Bank</FormLabel>
                                <Select
                                    name='bank_name'
                                    value={bankingForm.values.bank_name}
                                    onChange={bankingForm.handleChange}
                                    onBlur={bankingForm.handleBlur}
                                    placeholder='Select bank'
                                    focusBorderColor='#4caf50'
                                >
                                    <option value='first national bank'>First National Bank</option>
                                    <option value='absa'>ABSA</option>
                                    <option value='nedbank'>Nedbank</option>
                                    <option value='capitec'>Capitec</option>
                                    <option value='standard bank'>Standard Bank</option>
                                </Select>
                                {bankingForm.errors.bank_name && <Text fontSize={'sm'} color={'#f44336'}>{bankingForm.errors.bank_name}</Text>}
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Account type</FormLabel>
                                <Select
                                    name='account_type'
                                    value={bankingForm.values.account_type}
                                    onChange={bankingForm.handleChange}
                                    onBlur={bankingForm.handleBlur}
                                    placeholder='Select type'
                                    focusBorderColor='#4caf50'
                                >
                                    <option value='savings'>Savings</option>
                                    <option value='cheque'>Cheque</option>
                                    <option value='debit'>Debit</option>
                                </Select>
                                {bankingForm.errors.account_type && <Text fontSize={'sm'} color={'#f44336'}>{bankingForm.errors.account_type}</Text>}
                            </FormControl>
                        </SimpleGrid>
                        <SimpleGrid mt={4} columns={[1, 2, 2, 2, 2]} spacing={3}>
                            <FormControl isRequired>
                                <FormLabel>Account number</FormLabel>
                                <Input
                                    type={'text'}
                                    name='account_number'
                                    value={bankingForm.values.account_number}
                                    onChange={bankingForm.handleChange}
                                    onBlur={bankingForm.handleBlur}
                                    placeholder='12344'
                                    focusBorderColor='#4caf50'
                                    errorBorderColor={bankingForm.errors.account_number ? '#f44336' : 'inherit'}
                                />
                                {bankingForm.errors.account_number && <Text fontSize={'sm'} color={'#f44336'}>{bankingForm.errors.account_number}</Text>}
                            </FormControl>
                        </SimpleGrid>
                        <HStack mt={6} justifyContent={'flex-end'}>
                            <Button
                                colorScheme={'green'}
                                variant={'outline'}
                                minW={'120px'}
                                onClick={() => bankingForm.resetForm()}
                            >
                                Cancel
                            </Button>
                            <Button
                                colorScheme={'green'}
                                type={'submit'}
                                minW={'120px'}
                                isDisabled={bankingForm.isSubmitting || !bankingForm.isValid}
                                isLoading={bankingForm.isSubmitting}
                            >
                                Save
                            </Button>
                        </HStack>
                    </form>
                </Stack>
            </Stack>
        </>
    );
};