import { Button, HStack, Heading, SimpleGrid, Skeleton, Stack, Table, TableContainer, Tag, TagLabel, TagLeftIcon, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { FiCheck, FiMinus, FiX } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';
import { fetchDeliveries, getReferalProfile } from '../../store/actions';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { IoRefreshOutline } from 'react-icons/io5';
import { TbFileInvoice } from 'react-icons/tb';
import { deliveryType } from '../../types';
import moment from 'moment';

export const Invoices = () => {

    const [isRefreshing, setRefreshing] = useState<boolean>();

    const { profile } = useAppSelector(state => state.referalProfileReducer);
    const { deliveries, is_loading_deliveries } = useAppSelector(state => state.deliveriesReducer);

    const dispatch = useAppDispatch();

    const filterByStatus = (status: string) => {
        const _deliveries = deliveries.filter((delivery: deliveryType) => delivery.referer?.referal_invoice_status === status);
        return _deliveries;
    };

    const handleRefresh = async () => {
        setRefreshing(true);
        await dispatch(fetchDeliveries())
            .finally(() => setRefreshing(false));
    };

    useEffect(() => {
        dispatch(fetchDeliveries());
        return () => {
            if(profile === null) dispatch(getReferalProfile()).then(() => dispatch(fetchDeliveries()));
        }
    }, []);
    return (
        <>
            <Stack>
                <SimpleGrid columns={[1, 1, 3, 3, 3]} spacing={4}>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <TbFileInvoice color='#4caf50' size={'32px'} />
                        <Text color={'gray.500'}>Paid Invoices</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{filterByStatus('paid')?.length}<span style={{ fontSize: '18px', color: 'gray' }}>/{deliveries?.length}</span></Text>
                    </Stack>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <TbFileInvoice color='#9e9e9e' size={'32px'} />
                        <Text color={'gray.500'}>Unpaid Invoices</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{filterByStatus('unpaid')?.length}<span style={{ fontSize: '18px', color: 'gray' }}>/{deliveries?.length}</span></Text>
                    </Stack>
                    <Stack bg={'white'} p={4} borderRadius={4}>
                        <TbFileInvoice color='#f44336' size={'32px'} />
                        <Text color={'gray.500'}>Cancelled Invoices</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{filterByStatus('cancelled')?.length}<span style={{ fontSize: '18px', color: 'gray' }}>/{deliveries?.length}</span></Text>
                    </Stack>
                </SimpleGrid>
                {/* <Stack>
                    <SimpleGrid columns={[1, 2, 4, 4, 4]} spacing={4} mt={3} bg={'white'} p={4} borderRadius={4}>
                        <Stack>
                            <FormControl>
                                <FormLabel fontSize={'sm'} color={'gray.700'}>Search for Invoice ID</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <FiSearch color='#9e9e9e' />
                                    </InputLeftElement>
                                    <Input
                                        type='text'
                                        placeholder='Search'
                                        focusBorderColor='#4caf50'
                                    />
                                </InputGroup>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl>
                                <FormLabel fontSize={'sm'} color={'gray.700'}>Search for Delivery ID</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <FiSearch color='#9e9e9e' />
                                    </InputLeftElement>
                                    <Input
                                        type='text'
                                        placeholder='Search Delivery ID'
                                        focusBorderColor='#4caf50'
                                    />
                                </InputGroup>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl>
                                <FormLabel fontSize={'sm'} color={'gray.700'}>Status</FormLabel>
                                <Select placeholder='Select Status' focusBorderColor='#4caf50'>
                                    <option value='option1'>Paid</option>
                                    <option value='option2'>Unpaid</option>
                                    <option value='option3'>Cancelled</option>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl>
                                <FormLabel fontSize={'sm'} color={'gray.700'}>Search for customer name</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <FiSearch color='#9e9e9e' />
                                    </InputLeftElement>
                                    <Input
                                        type='text'
                                        placeholder='Customer Name'
                                        focusBorderColor='#4caf50'
                                    />
                                </InputGroup>
                            </FormControl>
                        </Stack>
                    </SimpleGrid>
                </Stack> */}
                <Stack pt={3}>
                    <SimpleGrid columns={[1]}>
                        <Stack bg={'white'} p={4} borderRadius={4}>
                            <HStack justifyContent={'space-between'}>
                                <Heading size={'sm'} fontWeight={'semibold'}>Invoices ({deliveries?.length})</Heading>
                                <Button leftIcon={<IoRefreshOutline />} size={'sm'} fontWeight={'medium'} colorScheme='green' variant='outline' isLoading={isRefreshing} isDisabled={isRefreshing} onClick={handleRefresh}>
                                    Refresh
                                </Button>
                            </HStack>
                            <TableContainer pt={3}>
                                <Table variant={'simple'} size={'md'}>
                                    <Thead>
                                        <Tr>
                                            <Th>Invoice id</Th>
                                            <Th>Delivery id</Th>
                                            <Th>Status</Th>
                                            <Th>Created At</Th>
                                            <Th>Commission (R)</Th>
                                            <Th textAlign={'end'}>Customer</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            deliveries?.map((delivery: deliveryType, index: number) => (
                                                <Tr key={index}>
                                                    <Td fontWeight={'medium'}>{delivery?.referer?.referal_invoice_id ? delivery?.referer?.referal_invoice_id : '-'}</Td>
                                                    <Td>{delivery?.delivery_id}</Td>
                                                    <Td>
                                                        <Tag size={'sm'} variant='subtle' colorScheme={delivery?.referer?.referal_invoice_status === 'paid' ? 'green' : delivery?.referer?.referal_invoice_status === 'cancelled' ? 'red' : 'gray'}>
                                                            <TagLeftIcon boxSize='12px' as={delivery?.referer?.referal_invoice_status === 'paid' ? FiCheck : delivery?.referer?.referal_invoice_status === 'cancelled' ? FiX : FiMinus} />
                                                            <TagLabel textTransform={'capitalize'}>{delivery?.referer?.referal_invoice_status}</TagLabel>
                                                        </Tag>
                                                    </Td>
                                                    <Td>{moment(delivery?.createdAt).format('DD MMM, YYYY HH:mm')}</Td>
                                                    <Td color={'green'} fontWeight={'semibold'}>R{Number(delivery?.referer?.commission)?.toFixed(2)}</Td>
                                                    <Td textAlign={'end'} textTransform={'capitalize'}>{delivery?.customer_name}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                                {
                                    !deliveries.length &&
                                    <Stack alignItems={'center'}>
                                        <TbFileInvoice fontSize={'100px'} color={'#9e9e9e'} />
                                        <Text color={'gray.500'}>No tasks available.</Text>
                                    </Stack>
                                }
                                {
                                    is_loading_deliveries &&
                                    <Stack>
                                        <Skeleton height='50px' w={'auto'} />
                                    </Stack>
                                }
                            </TableContainer>
                        </Stack>
                    </SimpleGrid>
                </Stack>
            </Stack>
        </>
    );
};