import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { vehicleType } from '../../types';

type initialStateType = {
    is_loading_vehicles: boolean,
    vehicles: vehicleType []
};

const initialState: initialStateType = {
    is_loading_vehicles: false,
    vehicles: []
};

export const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        setVehiclesLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                is_loading_vehicles: action.payload
            };
        },
        setVehicleSuccess: (state, action: PayloadAction<vehicleType []>) => {
            return {
                ...state,
                vehicles: action.payload
            };
        }
    }
});

export const { setVehiclesLoading, setVehicleSuccess } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;