/* eslint-disable */
import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    IconButton,
    Input,
    Stack,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import { Link as DefaultLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { AppDispatch } from '../../store';
import { FiArrowLeft } from 'react-icons/fi';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { forgotPasswordFormTypes } from '../../types';
import { forgotPasswordFormValidation } from '../../utils';
import { sentPasswordRestOTP } from '../../store/actions';
import { useFormik } from 'formik';

export const ForgotPassword = () => {

    const { is_sending_reset_password_otp } = useAppSelector(state => state.authReducer);

    const dispatch: AppDispatch = useAppDispatch();

    const navigate = useNavigate();

    const forgotPasswordForm = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: forgotPasswordFormValidation,
        onSubmit: (values: forgotPasswordFormTypes) => {
            dispatch(sentPasswordRestOTP(values)).then(() => navigate(`/reset-password`));
        },
    });

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Forgot password?</Heading>
                    <Text fontSize={'lg'} color={'gray.600'} textAlign='center'>
                        Enter you remail address to receive OTP, to reset your password.
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <HStack align={'center'}>
                            <Stack>
                                <IconButton aria-label='go back' icon={<FiArrowLeft style={{ width: '20px', height: '20px' }} />} as={DefaultLink} to='/login' />
                            </Stack>
                            <Stack>
                                <Text display={'flex'} fontSize={'md'} fontWeight='medium' textAlign={'center'}>
                                    Go back - Login
                                </Text>
                            </Stack>
                        </HStack>
                        <Divider />
                        <form onSubmit={forgotPasswordForm.handleSubmit}>
                            <FormControl id='email' mb={4}>
                                <FormLabel>Email address</FormLabel>
                                <Input focusBorderColor='#4caf50' type='email' name='email' value={forgotPasswordForm.values.email} onChange={forgotPasswordForm.handleChange} />
                                {forgotPasswordForm.errors.email && <Text fontSize='sm' color='red.400'>{forgotPasswordForm.errors.email}</Text>}
                            </FormControl>
                            <Stack spacing={10}>
                                <Button
                                    type='submit'
                                    isLoading={is_sending_reset_password_otp}
                                    colorScheme={'green'}
                                    disabled={!forgotPasswordForm.isValid || !forgotPasswordForm.values.email || is_sending_reset_password_otp}>
                                    Send OTP
                                </Button>
                            </Stack>
                        </form>
                    </Stack>
                </Box>
            </Stack>
            <ToastContainer />
        </Flex>
    );
};