import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authType } from '../../types';

const initialState: authType = {
    token: null,
    isAuthenticated: false,
    loginLoading: false,
    registerLoading: false,
    registerSuccess: null,
    message: '',
    id: '',
    is_sending_reset_password_otp: false,
    is_reseting_passsword: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<object>) => {
            const { token, success, _id }: any = action.payload;
            return {
                ...state,
                token: token,
                isAuthenticated: success,
                loginLoading: false,
                id: _id,
            };
        },
        IsloginLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loginLoading: action.payload,
            };
        },
        loginLoadingFailure: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loginLoading: false,
                message: action.payload
            };
        },
        isRegistering: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                registerLoading: action.payload
            };
        },
        registerComplete: (state, action: PayloadAction<object>) => {
            const { success, message }: any = action.payload;
            return {
                ...state,
                message: message,
                registerSuccess: success,
            };
        },
        logoutSuccess: state => {
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                message: '',
                id: ''
            };
        },
        onIsSendingOTP: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                is_sending_reset_password_otp: action.payload,
            };
        },
        onResetingPassword: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                is_reseting_passsword: action.payload,
            };
        },
    }
});

export const { loginSuccess, IsloginLoading, logoutSuccess, loginLoadingFailure, isRegistering, registerComplete, onIsSendingOTP, onResetingPassword } = authSlice.actions;

export default authSlice.reducer;