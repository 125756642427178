import BakkieImg from '../theme/assets/bakkie.png';
import BicycleImg from '../theme/assets/bicycle.jpg';
import EightTonsTruckImg from '../theme/assets/8-ton.jpg';
import FourTonsTruckImg from '../theme/assets/4-ton.jpg';
import HalfTruckImg from '../theme/assets/half-truck.jpg';
import MiniBakkieImg from '../theme/assets/mini-bakkie.jpg';
import ScooterImg from '../theme/assets/scooter.png';
import Van from '../theme/assets/van.png';

export enum FleetType {
    BICYCLE = 'bicycle',
    SCOOTER = 'scooter',
    MINI_BAKKIE = 'mini bakkie',
    BAKKIE = 'bakkie',
    HALF_TRUCK = 'half truck',
    FOUR_TONS_TRUCK = '4ton',
    EIGHT_TONS_TRUCK = '8ton',
    VAN = 'van',
}

export const Fleets = {
    BICYCLE: BicycleImg,
    SCOOTER: ScooterImg,
    MINI_BAKKIE: MiniBakkieImg,
    BAKKIE: BakkieImg,
    HALF_TRUCK: HalfTruckImg,
    FOUR_TONS_TRUCK: FourTonsTruckImg,
    EIGHT_TONS_TRUCK: EightTonsTruckImg,
    VAN: Van,
};