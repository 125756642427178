import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { AddTask, ForgotPassword, Home, Invoices, Login, Register, ResetPassword, Settings, Tasks } from './modules';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { persistor, store } from './store';

import AppContainer from './modules/AppContainer';
import { PersistGate } from 'redux-persist/integration/react';
import { ProtectedRoute } from './utils';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { inputTheme } from './theme';
import reportWebVitals from './reportWebVitals';

const theme = extendTheme({
  components: {
    Input: inputTheme
  },
});

const appRouting = (
  <Routes>
    <Route path='/login' element={<Login />} />
    <Route path='/register' element={<Register />} />
    <Route path='/forgot-password' element={<ForgotPassword />} />
    <Route path='/reset-password' element={<ResetPassword />} />
    <Route
      path='/'
      element={
        <ProtectedRoute
          component={<Home />}
        />
      }
    />
    <Route
      path='/home'
      element={
        <ProtectedRoute
          component={<Home />}
        />
      }
    />
    <Route
      path='/invoices'
      element={
        <ProtectedRoute
          component={<Invoices />}
        />
      }
    />
    <Route
      path='/tasks'
    >
      <Route
        path=''
        element={
          <ProtectedRoute
            component={<Tasks />}
          />
        }
      />
      <Route
        path='add'
        element={
          <ProtectedRoute
            component={<AddTask />}
          />
        }
      />
    </Route>
    <Route
      path='/settings'
      element={
        <ProtectedRoute
          component={<Settings />}
        />
      }
    />
  </Routes>
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading='null' persistor={persistor}>
      <ChakraProvider theme={theme}>
        <React.StrictMode>
          <BrowserRouter>
            <AppContainer>
              {appRouting}
            </AppContainer>
          </BrowserRouter>
        </React.StrictMode>
      </ChakraProvider>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
