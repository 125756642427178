import { Badge, Button, HStack, Heading, SimpleGrid, Skeleton, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { fetchDeliveries, getReferalProfile } from '../../store/actions';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { BiTask } from 'react-icons/bi';
import { IoRefreshOutline } from 'react-icons/io5';
import { TbTruckDelivery } from 'react-icons/tb';
import { deliveryType } from '../../types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const Tasks = () => {

    const [isRefreshing, setRefreshing] = useState<boolean>();

    const { profile } = useAppSelector(state => state.referalProfileReducer);
    const { deliveries, is_loading_deliveries } = useAppSelector(state => state.deliveriesReducer);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const filterByStatus = (status: string) => {
        const _deliveries = deliveries.filter((delivery: deliveryType) => delivery.status === status);
        return _deliveries;
    };

    const handleRefresh = async () => {
        setRefreshing(true);
        await dispatch(fetchDeliveries())
            .finally(() => setRefreshing(false));
    };

    useEffect(() => {
        dispatch(fetchDeliveries());
        return () => {
            if(profile === null) dispatch(getReferalProfile()).then(() => dispatch(fetchDeliveries()));
        }
    }, []);

    return (
        <>
            <Stack>
                <SimpleGrid columns={[1, 1, 3, 4, 4]} spacing={4}>
                    <Stack bg={'white'} p={6} borderRadius={6}>
                        <TbTruckDelivery color='#4caf50' size={'32px'} />
                        <Text color={'gray.500'}>All Deliveries</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{deliveries?.length}</Text>
                    </Stack>
                    <Stack bg={'white'} p={6} borderRadius={6}>
                        <TbTruckDelivery color='#4caf50' size={'32px'} />
                        <Text color={'gray.500'}>Delivered Delivery</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{filterByStatus('delivered')?.length}<span style={{ fontSize: '18px', color: 'gray' }}>/{deliveries?.length}</span></Text>
                    </Stack>
                    <Stack bg={'white'} p={6} borderRadius={6}>
                        <TbTruckDelivery color='#4caf50' size={'32px'} />
                        <Text color={'gray.500'}>Approved Delivery</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{filterByStatus('approved')?.length}<span style={{ fontSize: '18px', color: 'gray' }}>/{deliveries?.length}</span></Text>
                    </Stack>
                    <Stack bg={'white'} p={6} borderRadius={6}>
                        <TbTruckDelivery color='#f44336' size={'32px'} />
                        <Text color={'gray.500'}>Cancelled Delivery</Text>
                        <Text fontSize={'2xl'} color={'gray.700'} fontWeight={'extrabold'} lineHeight={'none'}>{filterByStatus('cancelled')?.length}<span style={{ fontSize: '18px', color: 'gray' }}>/{deliveries?.length}</span></Text>
                    </Stack>
                </SimpleGrid>
                {/* <Stack>
                    <SimpleGrid columns={[1, 2, 4, 4, 4]} spacing={4} mt={3} bg={'white'} p={4} borderRadius={4}>
                        <Stack>
                            <FormControl>
                                <FormLabel fontSize={'sm'} color={'gray.700'}>Search for Invoice ID</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <FiSearch color='#9e9e9e' />
                                    </InputLeftElement>
                                    <Input
                                        type='text'
                                        placeholder='Search'
                                        focusBorderColor='#4caf50'
                                    />
                                </InputGroup>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl>
                                <FormLabel fontSize={'sm'} color={'gray.700'}>Search for Delivery ID</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <FiSearch color='#9e9e9e' />
                                    </InputLeftElement>
                                    <Input
                                        type='text'
                                        placeholder='Search Delivery ID'
                                        focusBorderColor='#4caf50'
                                    />
                                </InputGroup>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl>
                                <FormLabel fontSize={'sm'} color={'gray.700'}>Status</FormLabel>
                                <Select placeholder='Select Status' focusBorderColor='#4caf50'>
                                    <option value='option1'>Paid</option>
                                    <option value='option2'>Unpaid</option>
                                    <option value='option3'>Cancelled</option>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl>
                                <FormLabel fontSize={'sm'} color={'gray.700'}>Search for customer name</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <FiSearch color='#9e9e9e' />
                                    </InputLeftElement>
                                    <Input
                                        type='text'
                                        placeholder='Customer Name'
                                        focusBorderColor='#4caf50'
                                    />
                                </InputGroup>
                            </FormControl>
                        </Stack>
                    </SimpleGrid>
                </Stack> */}
                <Stack pt={3}>
                    <SimpleGrid columns={[1]}>
                        <Stack bg={'white'} p={4} borderRadius={4}>
                            <HStack justifyContent={'space-between'}>
                                <Heading size={'sm'} fontWeight={'semibold'}>Delivery Task ({deliveries?.length})</Heading>
                                <HStack>
                                    <Button
                                        leftIcon={<IoRefreshOutline />}
                                        size={'sm'}
                                        fontWeight={'medium'}
                                        colorScheme='green'
                                        variant='outline'
                                        onClick={handleRefresh}
                                        isDisabled={isRefreshing}
                                        isLoading={isRefreshing}
                                    >
                                        Refresh
                                    </Button>
                                    <Button size={'sm'} fontWeight={'medium'} colorScheme='green' onClick={() => navigate('/tasks/add')}>
                                        Add Task
                                    </Button>
                                </HStack>
                            </HStack>
                            <TableContainer pt={3}>
                                <Table variant={'simple'} size={'md'}>
                                    <Thead>
                                        <Tr>
                                            <Th>Delivery</Th>
                                            <Th>Invoice</Th>
                                            <Th>Status</Th>
                                            <Th>Total (R)</Th>
                                            <Th>Commission (R)</Th>
                                            <Th>Date</Th>
                                            <Th>Pickup address</Th>
                                            <Th>Dropoff address</Th>
                                            <Th>Fullname</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            deliveries?.map((delivery: deliveryType, index: number) => (
                                                <Tr key={index}>
                                                    <Td fontWeight={'medium'}>{delivery?.delivery_id}</Td>
                                                    <Td>{delivery?.referer?.referal_invoice_id ? delivery?.referer?.referal_invoice_id : '-'}</Td>
                                                    <Td>
                                                        <Badge>{delivery?.status}</Badge>
                                                    </Td>
                                                    <Td color={'green.500'} fontWeight={'semibold'}>R{Number(delivery?.total)?.toFixed(2)}</Td>
                                                    <Td color={'green.500'} fontWeight={'semibold'}>R{Number(delivery?.referer?.commission)?.toFixed(2)}</Td>
                                                    <Td>{moment(delivery?.date).format('DD MMM, YYYY HH:mm')}</Td>
                                                    <Td>{delivery?.from?.address_from}</Td>
                                                    <Td>{delivery?.to?.address_to}</Td>
                                                    <Td textTransform={'capitalize'}>{delivery?.customer_name}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                                {
                                    !deliveries.length &&
                                    <Stack alignItems={'center'}>
                                        <BiTask fontSize={'100px'} color={'#9e9e9e'} />
                                        <Text color={'gray.500'}>No tasks available.</Text>
                                    </Stack>
                                }
                                {
                                    is_loading_deliveries &&
                                    <Stack>
                                        <Skeleton height='50px' w={'auto'} />
                                    </Stack>
                                }
                            </TableContainer>
                        </Stack>
                    </SimpleGrid>
                </Stack>
            </Stack>
        </>
    );
};

export * from './AddTask';