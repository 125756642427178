import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Container, Divider, FormControl, FormLabel, HStack, IconButton, Image, Input, Select, SimpleGrid, Skeleton, Stack, Text, Textarea, useToast } from '@chakra-ui/react';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { addCustomerFormValidation, addDeliveryFormValidation, getFleetImage } from '../../utils';
import { customerType, deliveryType, fleetType } from '../../types';
import { fetchFleets, getCompany, getReferalProfile, headers } from '../../store/actions';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { BsTruck } from 'react-icons/bs';
import { Link as DefaultLink } from 'react-router-dom';
import { DistanceMatrixService } from '@react-google-maps/api';
import { FiArrowLeft } from 'react-icons/fi';
import { TermsAndConditions } from '../../components';
import VanImage from '../../theme/assets/van.png';
import axios from 'axios';
import moment from 'moment';
import { useFormik } from 'formik';

export const AddTask = () => {

    const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API || '';

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    const toast = useToast();

    const dispatch = useAppDispatch();

    const DRIVING: any = 'DRIVING';

    const [origin, setOrigin] = useState<any | null>(null);
    const [originCoords, setOriginCoords] = useState<any | null>(null);

    const [destination, setDestination] = useState<any | null>(null);
    const [desCoords, setDesCoords] = useState<any | null>(null);

    const [distance, setDistance] = useState<number>(0);
    const [fleet, setFleet] = useState<fleetType>();

    const { fleets, is_loading_fleets } = useAppSelector(state => state.fleetReducer);

    const { user } = useAppSelector(state => state.userReducer);

    const { token } = useAppSelector(state => state.authReducer);

    const { profile } = useAppSelector(state => state.referalProfileReducer);
    const { referer_profile_id, user_id } = profile ?? {};

    const { company } = useAppSelector(state => state.companyReducer);
    const { settings, referal_rate, website, company_id, name, email } = company ?? {};
    const { payment } = settings ?? {};
    const { terms_and_conditions } = website ?? {};

    const addressInputs: any = document.querySelector('.css-1pahdxg-control');

    const customerForm = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            company_id: '',
            company_name: '',
            company_email: '',
        },
        validationSchema: addCustomerFormValidation,
        validateOnMount: true,
        onSubmit: (values: customerType & { company_name?: string; company_email?: string; }) => {
            setTimeout(() => {
                customerForm?.setSubmitting(false);
            }, 1000 * 2);
        }
    });

    const deliveryForm = useFormik({
        initialValues: {
            customer_id: '',
            customer_name: '',
            phone: '',
            payment_method: '',
            fleet_amount: 0,
            fleet: '',
            loaders: 0,
            loaders_amount: '',
            vat: 0,
            description: '',
            from: {
                address_from: '',
                from_lat: 0,
                from_long: 0
            },
            to: {
                address_to: '',
                to_lat: 0,
                to_long: 0
            },
            date: '',
            time: '',
            total: 0,
            distance: 0,
            distance_amount: 0,
            company_id: ''
        },
        validationSchema: addDeliveryFormValidation,
        validateOnMount: true,
        onSubmit: async (values: deliveryType) => {
            console.log({ values });
        }
    });

    const subTotal = Number(deliveryForm?.values?.fleet_amount) + Number(deliveryForm?.values?.loaders_amount) + (Number(distance) * Number(fleet?.per_km));
    const total = Number(subTotal) + (payment?.vat ? Number(subTotal) * 0.15 : 0);

    const setPickupAddressValues = async () => {
        await deliveryForm?.setFieldValue('from.address_from', origin?.label);
        await deliveryForm?.setFieldValue('from.from_lat', Number(originCoords?.lat));
        await deliveryForm?.setFieldValue('from.from_long', Number(originCoords?.long));
    };

    const setdropoffAddressValues = async () => {
        await deliveryForm?.setFieldValue('to.address_to', destination?.label);
        await deliveryForm?.setFieldValue('to.to_lat', Number(desCoords?.lat));
        await deliveryForm?.setFieldValue('to.to_long', Number(desCoords?.long));
    };

    const handleBookDelivery = async (customer_id: string, customer_name: string, phone: string) => {

        await setPickupAddressValues();

        await setdropoffAddressValues();

        const from = {
            address_from: origin?.label,
            from_lat: originCoords?.lat,
            from_long: originCoords?.long
        };

        const to = {
            address_to: destination?.label,
            to_lat: desCoords?.lat,
            to_long: desCoords?.long
        };

        const newValues = {
            ...deliveryForm?.values,
            customer_id,
            customer_name,
            phone,
            total,
            from,
            to,
            distance,
            distance_amount: Number(distance) * Number(fleet?.per_km),
            terms_and_conditions: `${terms_and_conditions[terms_and_conditions?.length - 1]}`,
            vat: payment?.vat ? Number(subTotal) * 0.15 : 0,
            referer: {
                referer_profile_id: referer_profile_id || '',
                fullname: `${user?.first_name} ${user?.last_name}` || '',
                phone: `${user?.phone}` || '',
                email: `${user?.email}` || '',
                user_id: user_id || '',
                commission: (Number(total) * (Number(referal_rate) / 100))?.toFixed(2) || 0
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_MANAGE_SERVICE_ENDPOINT}/delivery/referer/add`, { ...newValues }, headers(token));

        const { success, message } = data;

        try {
            deliveryForm?.setSubmitting(false);
            toast({
                title: `Delivery ${success ? '' : 'not'} created.`,
                description: message,
                status: success ? 'success' : 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });

        } catch (error: any) {
            toast({
                title: 'Delivery not created.',
                description: error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
            deliveryForm?.setSubmitting(false);
            console.error(error);

        }
        deliveryForm?.setSubmitting(false);

    };

    const handleAddCustomer = async () => {

        const { data } = await axios.post(`${process.env.REACT_APP_MANAGE_SERVICE_ENDPOINT}/customer/add`, { ...customerForm?.values });

        const { success, message, customer } = data;

        const { customer_id, first_name, last_name, phone } = customer;

        try {

            handleBookDelivery(customer_id, `${first_name} ${last_name}`, `${phone}`);
            toast({
                title: `Customer ${success ? '' : 'not'} created.`,
                description: message,
                status: success ? 'success' : 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
            customerForm?.setSubmitting(false);

        } catch (error: any) {

            toast({
                title: 'Customer not created.',
                description: error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
            customerForm?.setSubmitting(false);
            console.error(error);
        }
        customerForm?.setSubmitting(false);
    };

    const handleBooking = async () => {

        customerForm?.setSubmitting(true);
        deliveryForm?.setSubmitting(true);

        await customerForm.setFieldValue('company_id', company_id);

        await handleAddCustomer();
        await deliveryForm?.submitForm();

        customerForm.resetForm();
        deliveryForm.resetForm();

    };

    const getOriginCoords = useCallback(() => {
        origin && geocodeByAddress(origin?.label)
            .then((results: Array<any>) => getLatLng(results[0]))
            .then(async ({ lat, lng }: any) => {
                setOriginCoords({ lat: lat, long: lng });
                await setPickupAddressValues();
            });


    }, [origin]);

    const getDesCoords = useCallback(async () => {
        await destination && geocodeByAddress(destination?.label)
            .then((results: Array<any>) => getLatLng(results[0]))
            .then(async ({ lat, lng }: any) => {
                setDesCoords({ lat: lat, long: lng });
                await setdropoffAddressValues();
            });
    }, [destination]);

    const setFormValues = () => {
        customerForm?.setFieldValue('company_id', company_id);
        customerForm?.setFieldValue('company_name', name);
        customerForm?.setFieldValue('company_email', email);
        deliveryForm?.setFieldValue('company_id', company_id);
    };

    useEffect(() => {
        getOriginCoords();
        getDesCoords();
    }, [origin, destination]);

    useEffect(() => {
        dispatch(fetchFleets());
        dispatch(getReferalProfile());
        dispatch(getCompany());
        setFormValues();
        return () => setFormValues();
    }, []);

    useEffect(() => {
        deliveryForm?.setFieldValue('distance', distance);

        if (addressInputs) {
            addressInputs.style.boxShadow = `0 0 0 1 #4caf50 !important`;

            addressInputs.addEventListener('focus', () => {
                addressInputs.style.borderColor = '#4caf50';
            });
        }

    }, []);

    useEffect(() => {
        setFormValues();
    }, [company_id, name, email]);

    return (
        <Container minW={'-webkit-fill-available'} pt={16}>
            <Stack minW={'-webkit-fill-available'} bgColor={'white'} p={4} borderRadius={6}>
                <HStack mb={4}>
                    <IconButton aria-label='go back' icon={<FiArrowLeft style={{ width: '20px', height: '20px' }} />} as={DefaultLink} to='/tasks' />
                    <Text fontWeight={'medium'} fontSize={'xl'}>Add New Task</Text>
                </HStack>
                <form>
                    <SimpleGrid columns={[1, 1, 2, 2]} spacing={'15px'} mb={4}>
                        <FormControl isRequired>
                            <FormLabel>Pick-up address</FormLabel>
                            <GooglePlacesAutocomplete
                                apiKey={REACT_APP_GOOGLE_API_KEY}
                                apiOptions={{ region: 'za' }}
                                selectProps={{
                                    placeholder: 'Pick up',
                                    origin,
                                    onChange: setOrigin,
                                    styles: {
                                        input: (provided: any) => ({
                                            ...provided,
                                            maxWidth: '250px !important',
                                            width: '250px'
                                        })
                                    },
                                }}
                            />
                            {!origin?.label && <Text color={'gray.400'} fontSize={'sm'}>Enter pick-up address</Text>}
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Drop-off address</FormLabel>
                            <GooglePlacesAutocomplete
                                apiKey={REACT_APP_GOOGLE_API_KEY}
                                apiOptions={{ region: 'za' }}
                                selectProps={{
                                    placeholder: 'Drop off',
                                    origin,
                                    onChange: setDestination,
                                    styles: {
                                        input: (provided: any) => ({
                                            ...provided,
                                            maxWidth: '250px',
                                            width: '250px'
                                        })
                                    }
                                }}
                            />
                            {!destination?.label && <Text color={'gray.400'} fontSize={'sm'}>Enter drop-off address</Text>}
                        </FormControl>
                    </SimpleGrid>
                </form>
                {origin && destination && <DistanceMatrixService
                    options={{
                        destinations: [{ lat: desCoords?.lat, lng: desCoords?.long }],
                        origins: [{ lng: originCoords?.long, lat: originCoords?.lat }],
                        travelMode: DRIVING,
                    }}
                    callback={(response) => {
                        const _distance = (response?.rows[0]?.elements[0]?.distance?.text)?.split(' ')[0];
                        setDistance(Number(_distance));
                    }}
                />}
                <Stack>
                    <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
                        <AccordionItem borderTop={'none'} boxShadow={'md'} borderRadius={4} mb={4} borderColor={(deliveryForm?.values?.fleet || deliveryForm?.values?.loaders_amount) ? 'green.500' : 'red.500'}>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'} textTransform={'capitalize'}>
                                        Select fleet
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <Divider />
                            <AccordionPanel pb={4}>
                                {
                                    !fleets.length &&
                                    <Stack alignItems={'center'}>
                                        <BsTruck fontSize={'100px'} color={'#9e9e9e'} />
                                        <Text color={'gray.500'}>No fleets available.</Text>
                                    </Stack>
                                }
                                <SimpleGrid columns={[1, 1, 2, 3, 3]} spacing={6}>
                                    {
                                        fleets?.map((fleet: fleetType, index: number) => (
                                            <HStack key={index}>
                                                <Image maxH={'100px'} maxW={'100px'} src={fleet?.fleet_size === 'van' ? VanImage : getFleetImage(`${fleet?.fleet_size}`)} alt={fleet?.fleet_size} />
                                                <Stack pl={2}>
                                                    <Text textTransform={'capitalize'} fontWeight={'bold'}>{fleet?.fleet_size}</Text>
                                                    <Text fontSize={'12px'} color={'gray.500'}>{fleet?.description}</Text>
                                                    <Button colorScheme={'green'} size={'sm'}
                                                        variant={deliveryForm?.values?.fleet === fleet?.fleet_size ? 'solid' : 'outline'}
                                                        onClick={() => {
                                                            deliveryForm?.setFieldValue('fleet', fleet?.fleet_size);
                                                            deliveryForm?.setFieldValue('fleet_amount', fleet?.fleet_amount);
                                                            deliveryForm?.setFieldValue('loaders_amount', fleet?.loader_amount);
                                                            deliveryForm?.setFieldValue('distance_amount', Number(distance) * Number(fleet?.per_km));
                                                            setFleet(fleet);
                                                        }}>Select</Button>
                                                </Stack>
                                            </HStack>
                                        ))
                                    }
                                </SimpleGrid>
                                {
                                    is_loading_fleets &&
                                    <SimpleGrid columns={[1, 1, 2, 3, 3]} spacing={6} mt={4}>
                                        <Skeleton height='80px' w={'auto'} />
                                        <Skeleton height='80px' w={'auto'} />
                                        <Skeleton height='80px' w={'auto'} />
                                    </SimpleGrid>
                                }
                            </AccordionPanel>
                        </AccordionItem>

                        <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={6}>
                            <AccordionItem borderTop={'none'} boxShadow={'md'} borderRadius={4} mb={4} borderColor={deliveryForm?.isValid ? 'green.500' : 'red.500'}>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'} textTransform={'capitalize'}>
                                            Delivery details
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <Divider />
                                <AccordionPanel pb={4}>
                                    <form onSubmit={deliveryForm?.handleSubmit}>
                                        <FormControl isRequired>
                                            <FormLabel>Payment method</FormLabel>
                                            <Select
                                                value={deliveryForm?.values?.payment_method}
                                                onChange={deliveryForm?.handleChange}
                                                onBlur={deliveryForm?.handleBlur}
                                                name='payment_method'
                                                placeholder='Select payment option'
                                                colorScheme={'green'}
                                                focusBorderColor='#4caf50'
                                            >
                                                <option value='cash'>Cash</option>
                                                <option value='card'>Card</option>
                                                <option value='eft'>EFT</option>
                                            </Select>
                                            {deliveryForm?.errors?.payment_method && <Text color={'red.400'} fontSize={'sm'}>{deliveryForm?.errors?.payment_method}</Text>}
                                        </FormControl>
                                        <FormControl mt={3} isRequired>
                                            <FormLabel>Date and Time</FormLabel>
                                            <Input
                                                value={deliveryForm?.values?.date}
                                                onChange={async (event) => {
                                                    await deliveryForm?.setFieldValue('date', event.target.value)
                                                        .then(() => {
                                                            deliveryForm?.setFieldValue('time', moment(event.target.value).format('HH:mm'));
                                                        });
                                                }}
                                                onBlur={deliveryForm?.handleBlur}
                                                name='date'
                                                colorScheme={'green'}
                                                focusBorderColor='#4caf50'
                                                placeholder="Select Date and Time"
                                                size="md"
                                                type="datetime-local"
                                            />
                                            {deliveryForm?.errors?.date && <Text color={'red.400'} fontSize={'sm'}>{deliveryForm?.errors?.date}</Text>}
                                        </FormControl>
                                        <FormControl mt={3} isRequired>
                                            <FormLabel>Loaders/Helpers</FormLabel>
                                            <Select
                                                placeholder='Select loaders'
                                                onChange={async (event) => {
                                                    const { value } = event.target;
                                                    await deliveryForm?.setFieldValue('loaders', value);
                                                    deliveryForm?.setFieldValue('loaders_amount', Number(value) * Number(fleet?.loader_amount));
                                                }}
                                                onBlur={deliveryForm?.handleBlur}
                                                value={deliveryForm?.values?.loaders} name='loaders'
                                                colorScheme={'green'}
                                                focusBorderColor='#4caf50'
                                            >
                                                <option value='0'>0 - R{0 * Number(fleet?.loader_amount)}</option>
                                                <option value='1'>1 - R{1 * Number(fleet?.loader_amount)}</option>
                                                <option value='2'>2 - R{2 * Number(fleet?.loader_amount)}</option>
                                                <option value='3'>3 - R{3 * Number(fleet?.loader_amount)}</option>
                                                <option value='4'>4 - R{4 * Number(fleet?.loader_amount)}</option>
                                                <option value='5'>5 - R{5 * Number(fleet?.loader_amount)}</option>
                                                <option value='6'>6 - R{8 * Number(fleet?.loader_amount)}</option>
                                                <option value='7'>7 - R{7 * Number(fleet?.loader_amount)}</option>
                                                <option value='8'>8 - R{8 * Number(fleet?.loader_amount)}</option>
                                                <option value='9'>9 - R{9 * Number(fleet?.loader_amount)}</option>
                                                <option value='10'>10 - R{10 * Number(fleet?.loader_amount)}</option>
                                            </Select>
                                            {deliveryForm?.errors?.loaders && <Text color={'red.400'} fontSize={'sm'}>{deliveryForm?.errors?.loaders}</Text>}
                                        </FormControl>
                                        <FormControl mt={3} isRequired>
                                            <FormLabel>Additional information</FormLabel>
                                            <Textarea bg={'#ffffff'}
                                                value={deliveryForm?.values?.description}
                                                onChange={deliveryForm?.handleChange}
                                                onBlur={deliveryForm?.handleBlur}
                                                name='description'
                                                colorScheme={'green'}
                                                focusBorderColor='#4caf50'
                                                placeholder='Provide description' />
                                            {deliveryForm?.errors?.description && <Text color={'red.400'} fontSize={'sm'}>{deliveryForm?.errors?.description}</Text>}
                                        </FormControl>
                                    </form>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem borderTop={'none'} boxShadow={'md'} borderRadius={4} mb={4} borderColor={customerForm?.isValid ? 'green.500' : 'red.500'}>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'} textTransform={'capitalize'}>
                                            Client details
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <Divider />
                                <AccordionPanel pb={4}>
                                    <form onSubmit={customerForm.handleSubmit}>
                                        <FormControl mt={3} isRequired>
                                            <FormLabel>First name</FormLabel>
                                            <Input bg={'#ffffff'}
                                                value={customerForm?.values?.first_name}
                                                onChange={customerForm?.handleChange}
                                                onBlur={customerForm?.handleBlur}
                                                name='first_name'
                                                colorScheme={'green'}
                                                focusBorderColor='#4caf50'
                                                placeholder='John'
                                            />
                                            {customerForm?.errors?.first_name && <Text color={'red.400'} fontSize={'sm'}>{customerForm?.errors?.first_name}</Text>}
                                        </FormControl>
                                        <FormControl mt={3} isRequired>
                                            <FormLabel>Last name</FormLabel>
                                            <Input bg={'#ffffff'}
                                                value={customerForm?.values?.last_name}
                                                onChange={customerForm?.handleChange}
                                                onBlur={customerForm?.handleBlur}
                                                name='last_name'
                                                colorScheme={'green'}
                                                focusBorderColor='#4caf50'
                                                placeholder='Doe'
                                            />
                                            {customerForm?.errors?.last_name && <Text color={'red.400'} fontSize={'sm'}>{customerForm?.errors?.last_name}</Text>}
                                        </FormControl>
                                        <FormControl mt={3} isRequired>
                                            <FormLabel>Phone number</FormLabel>
                                            <Input bg={'#ffffff'}
                                                value={customerForm?.values?.phone}
                                                onChange={customerForm?.handleChange}
                                                onBlur={customerForm?.handleBlur}
                                                name='phone'
                                                type='tel'
                                                colorScheme={'green'}
                                                focusBorderColor='#4caf50'
                                                placeholder='0000000000'
                                            />
                                            {customerForm?.errors?.phone && <Text color={'red.400'} fontSize={'sm'}>{customerForm?.errors?.phone}</Text>}
                                        </FormControl>
                                        <FormControl mt={3} isRequired>
                                            <FormLabel>Email</FormLabel>
                                            <Input bg={'#ffffff'}
                                                value={customerForm?.values?.email}
                                                onChange={customerForm?.handleChange}
                                                onBlur={customerForm?.handleBlur}
                                                name='email'
                                                colorScheme={'green'}
                                                focusBorderColor='#4caf50'
                                                placeholder='john@doe.com'
                                            />
                                            {customerForm?.errors?.email && <Text color={'red.400'} fontSize={'sm'}>{customerForm?.errors?.email}</Text>}
                                        </FormControl>
                                    </form>
                                </AccordionPanel>
                            </AccordionItem>
                        </SimpleGrid>
                    </Accordion>
                </Stack>
                <Stack mb={3}>
                    <Stack bg={'gray.100'} borderRadius={4} p={3}>
                        <Text fontSize={'sm'}>Fleet Amount: R{Number(deliveryForm?.values?.fleet_amount)?.toFixed(2)}</Text>
                        <Text fontSize={'sm'}>Loaders Amount: R{Number(deliveryForm?.values?.loaders_amount)?.toFixed(2)}</Text>
                        <Text fontSize={'sm'}>Distance: {Number(distance)} km</Text>
                        <Text fontSize={'sm'}>Distance Amount: R{(Number(distance) * Number(fleet?.per_km)).toFixed(2)}</Text>
                        <Text fontSize={'sm'}>Vat: R{payment?.vat ? (Number(subTotal) * 0.15)?.toFixed(2) : 0}</Text>
                        <Text fontSize={'sm'} color={'green.500'} fontWeight={'bold'}>Commission: R{(Number(total) * (Number(referal_rate) / 100))?.toFixed(2) || 0}</Text>
                        <Text fontWeight={'bold'}>Total: R{Number(total)?.toFixed(2)}</Text>
                    </Stack>
                    <Checkbox
                        colorScheme={'green'}
                        isChecked={termsAccepted}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                    >
                        <TermsAndConditions colorScheme={'green'} terms_and_conditions={terms_and_conditions} textColor={'black'} />
                    </Checkbox>
                    {!termsAccepted && <Text color={'red.400'} fontSize={'sm'}>Accept terms and conditions to proceed.</Text>}
                    <Button
                        alignSelf={'end'}
                        minW={230}
                        colorScheme={'green'}
                        isLoading={customerForm?.isSubmitting || deliveryForm?.isSubmitting}
                        disabled={customerForm?.isSubmitting || !customerForm?.isValid || deliveryForm?.isSubmitting || !deliveryForm?.isValid || !termsAccepted}
                        onClick={() => handleBooking()}
                    >
                        Book
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
};