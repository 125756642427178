import { Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

import DOMPurify from 'dompurify';
import React from 'react';

export const TermsAndConditions = (props: { colorScheme?: string; terms_and_conditions?: Array<string>; textColor?: string; }) => {

    const { colorScheme, terms_and_conditions = [], textColor } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    const terms = terms_and_conditions[terms_and_conditions?.length - 1] ?? '<p></p>';

    const createMarkup = (html: string | null | any) => {
        return {
            __html: DOMPurify.sanitize(html)
        };
    };

    return (
        <>
            <Text color={textColor || 'white'} onClick={onOpen}>Terms and conditions</Text>
            <Modal isOpen={isOpen} onClose={onClose} colorScheme={colorScheme}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Terms and Conditions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Divider />
                        <div
                            style={{
                                padding: '1rem',
                                marginTop: '1rem'
                            }}
                            dangerouslySetInnerHTML={createMarkup(terms)}>
                        </div>Ï
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};