import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Select,
    Stack,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import { Link as DefaultLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { AppDispatch } from '../../store';
import { ToastContainer } from 'react-toastify';
import { register } from '../../store/actions';
import { registerComplete } from '../../store/reducers';
import { registerFormTypes } from '../../types';
import { registerFormValidation } from '../../utils';
import { useFormik } from 'formik';

export function Register() {
    const [showPassword, setShowPassword] = useState(false);

    const { registerLoading, registerSuccess } = useAppSelector(state => state.authReducer);

    const dispatch: AppDispatch = useAppDispatch();

    // eslint-disable-next-line
    const navigate = useNavigate();

    const _message = '';

    const registerForm = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            gender: '',
            password: '',
        },
        validationSchema: registerFormValidation,
        validateOnBlur: true,
        onSubmit: (values: registerFormTypes) => {
            dispatch(register(values)).then(() => navigate('/login'));
            console.log({values});
        },
    });

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack>
                        <Text textAlign={'center'} color={'gray.500'} fontWeight={'semibold'}>Join other hustlers.</Text>
                        <Heading fontSize={'4xl'}>
                            Sign up
                        </Heading>
                        <Text fontSize={14} color={'gray.500'}>
                            To enjoy all of our cool features ✌️
                        </Text>
                    </Stack>
                    <Stack spacing={4} mt={6}>
                        <form onSubmit={registerForm.handleSubmit}>
                            <HStack>
                                <Box>
                                    <FormControl id="firstName" isRequired mb={registerForm.errors.first_name ? 0 : 5}>
                                        <FormLabel>First name</FormLabel>
                                        <Input name='first_name' type="text" value={registerForm.values.first_name} onChange={registerForm.handleChange} onBlur={registerForm.handleBlur} />
                                        {registerForm.errors.first_name && <Text fontSize='sm' color='red.400'>{registerForm.errors.first_name}</Text>}
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl id="lastName" isRequired mb={registerForm.errors.last_name ? 0 : 5}>
                                        <FormLabel>Last name</FormLabel>
                                        <Input name='last_name' type="text" value={registerForm.values.last_name} onChange={registerForm.handleChange} onBlur={registerForm.handleBlur} />
                                        {registerForm.errors.last_name && <Text fontSize='sm' color='red.400'>{registerForm.errors.last_name}</Text>}
                                    </FormControl>
                                </Box>
                            </HStack>
                            <FormControl id="email" isRequired mb={registerForm.errors.email ? 0 : 5}>
                                <FormLabel>Email address</FormLabel>
                                <Input name='email' type="email" value={registerForm.values.email} onChange={registerForm.handleChange} onBlur={registerForm.handleBlur} />
                                {registerForm.errors.email && <Text fontSize='sm' color='red.400'>{registerForm.errors.email}</Text>}
                            </FormControl>
                            <HStack>
                                <Box>
                                    <FormControl id="phone" isRequired mb={registerForm.errors.phone ? 0 : 5}>
                                        <FormLabel>Phone number</FormLabel>
                                        <Input name='phone' type="tel" value={registerForm.values.phone} onChange={registerForm.handleChange} onBlur={registerForm.handleBlur} />
                                        {registerForm.errors.phone && <Text fontSize='sm' color='red.400'>{registerForm.errors.phone}</Text>}
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl id="gender" isRequired mb={registerForm.errors.gender ? 0 : 5}>
                                        <FormLabel>Gender</FormLabel>
                                        <Select name='gender' placeholder='Select gender' value={registerForm.values.gender} onChange={registerForm.handleChange} onBlur={registerForm.handleBlur}>
                                            <option value='male'>Male</option>
                                            <option value='female'>Female</option>
                                        </Select>
                                        {registerForm.errors.gender && <Text fontSize='sm' color='red.400'>{registerForm.errors.gender}</Text>}
                                    </FormControl>
                                </Box>
                            </HStack>
                            <FormControl id="password" isRequired mb={registerForm.errors.password ? 0 : 5}>
                                <FormLabel>Password</FormLabel>
                                <InputGroup>
                                    <Input name='password' type={showPassword ? 'text' : 'password'} value={registerForm.values.password} onChange={registerForm.handleChange} onBlur={registerForm.handleBlur} />
                                    <InputRightElement h={'full'}>
                                        <Button
                                            variant={'ghost'}
                                            onClick={() =>
                                                setShowPassword((showPassword) => !showPassword)
                                            }>
                                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                {registerForm.errors.password && <Text fontSize='sm' color='red.400'>{registerForm.errors.password}</Text>}
                            </FormControl>
                            <Stack spacing={10} pt={2}>
                                <Button
                                    type='submit'
                                    loadingText="Submitting"
                                    isLoading={registerLoading}
                                    size="lg"
                                    colorScheme={'green'}
                                    disabled={registerLoading}
                                    onClick={() => dispatch(register(registerForm.values)).then(() => navigate('/login'))}
                                    >
                                    Sign up
                                </Button>
                            </Stack>
                            <Stack pt={6}>
                                <Text align={'center'}>
                                    Already a user? <Link as={DefaultLink} color={'green.500'} fontWeight={'medium'} to='/login' onClick={() => dispatch(registerComplete({ registerSuccess, _message }))}>Login</Link>
                                </Text>
                            </Stack>
                        </form>
                    </Stack>
                </Box>
            </Stack>
            <ToastContainer />
        </Flex>
    );
}