import { AppDispatch, RootState } from '..';
import { REACT_APP_MANAGE_SERVICE_ENDPOINT, headers } from '.';

import axios from 'axios';
import { bankingDetailsType } from '../../types';
import { referalProfileSuccess } from '../reducers';

export const getReferalProfile = () => async (dispatch: AppDispatch, getState: RootState) => {

    const { token } = getState().authReducer;
    const { user } = getState().userReducer;
    const { user_id } = user;

    try {

        const { data } = await axios.get(`${REACT_APP_MANAGE_SERVICE_ENDPOINT}/referal-profile/${user_id}`, headers(token));

        const { referal_profile } = data;

        dispatch(referalProfileSuccess(referal_profile));

        // if (referal_profile === null) {
        //     dispatch(createReferalProfile(user_id));
        // } else {
        //     dispatch(referalProfileSuccess(referal_profile));
        // }

    } catch (error) {

        console.error(error);

    }
};

export const createReferalProfile = (user_id: string) => async (dispatch: AppDispatch, getState: RootState) => {

    const { token } = getState().authReducer;

    try {

        const { data } = await axios.post(`${REACT_APP_MANAGE_SERVICE_ENDPOINT}/referer-profile/add`, { user_id }, headers(token));

        const { success } = data;

        if (success) dispatch(getReferalProfile());

    } catch (error) {

        console.error(error);

    }

};

export const updateBankingDetails = (values: bankingDetailsType) => async (dispatch: AppDispatch, getState: RootState) => {

    const { token } = getState().authReducer;
    const { profile } = getState().referalProfileReducer;
    const { referer_profile_id } = profile;

    try {

        await axios.put(`${REACT_APP_MANAGE_SERVICE_ENDPOINT}/referer-profile/update-banking-details`, { ...values, referer_profile_id }, headers(token))
            .then(() => dispatch(getReferalProfile()));

    } catch (error) {
        console.error(error);
    }

};