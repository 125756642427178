import { AppDispatch, RootState } from '..';
import { REACT_APP_AUTH_SERVICE_ENDPOINT, fetchDeliveries, getReferalProfile, headers } from '.';
import { forgotPasswordFormTypes, loginFormTypes, registerFormTypes, resetPasswordFormTypes } from '../../types';
import { isRegistering, loginSuccess, onIsSendingOTP, onResetingPassword, registerComplete, userSuccess } from '../reducers';

import axios from 'axios';
import { notification } from '../../utils';

export const login = (values: loginFormTypes, emailCb: () => void, passwordCb: () => void) => async (dispatch: AppDispatch) => {

    const { data } = await axios.post(`${REACT_APP_AUTH_SERVICE_ENDPOINT}/signin`, { ...values }, {
        headers: {
            role: 'referer',
        }
    });

    const { success, message, user, token } = data;
    const { _id } = user ?? '';

    try {

        dispatch(loginSuccess({ token, success, _id }));
        dispatch(userSuccess({ user }));
        notification(success ? 'success' : 'error', message);
        if(message === 'Email is invalid') emailCb();
        if(message === 'Invalid password.') passwordCb();
        dispatch(getReferalProfile());
        dispatch(fetchDeliveries());

    } catch (error: any) {

        notification('error', message);
        console.log(error.message);

    }

};

export const register = (values: registerFormTypes) => async (dispatch: AppDispatch) => {

    dispatch(isRegistering(true));

    const { data } = await axios.post(`${REACT_APP_AUTH_SERVICE_ENDPOINT}/signup`, { ...values, role: 'referer' });

    const { success, message } = data;

    try {

        dispatch(isRegistering(false));
        dispatch(registerComplete({ success, message }));
        notification(success ? 'success' : 'error', message);

    } catch (error: any) {

        dispatch(isRegistering(false));
        notification('error', message);
        dispatch(registerComplete(error.message));
        console.log(error.message);

    }

};

export const getUser = () => async (dispatch: AppDispatch, getState: RootState) => {

    const { id, token } = getState().authReducer;
    const { profile } = getState().referalProfileReducer;

    axios
        .get(`${REACT_APP_AUTH_SERVICE_ENDPOINT}/user/${id}`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        .then(res => {

            const { user } = res.data;

            try {
                dispatch(userSuccess({ user }));
                if(profile === null) dispatch(getReferalProfile());
            } catch (error) {
                console.error(error);
            }
        });
};

export const updateUserCompanyId = (company_id: string) => async (dispatch: AppDispatch, getState: RootState) => {

    const { token } = getState().authReducer;

    const { user } = getState().userReducer;
    const { user_id } = user;

    const { data } = await axios.put(`${REACT_APP_AUTH_SERVICE_ENDPOINT}/user/update-company-id`, { user_id, company_id }, headers(token));

    const { success, message } = data;

    try {

        notification(success ? 'success' : 'error', message);
        
    } catch (error: any) {

        notification('error', error.message);
        console.log(error.message);
        
    }
};

export const uploadImage = (formData: any) => async (dispatch: AppDispatch, getState: RootState) => {

    const { token } = getState().authReducer;
    const { user } = getState().userReducer;

    const { user_id } = user;

    const { data } = await axios({
        method: 'post',
        url: `${REACT_APP_AUTH_SERVICE_ENDPOINT}/user/${user_id}/upload-image`,
        data: formData,
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    });

    const { success, message } = data;

    try {

        notification(success ? 'success' : 'error', message);
        dispatch(getUser());

    } catch (error: any) {

        notification(success ? 'success' : 'error', error.message);
        console.log(error.message);

    }
};

export const sentPasswordRestOTP = (values: forgotPasswordFormTypes) => async (dispatch: AppDispatch) => {

    dispatch(onIsSendingOTP(true));

    const { data } = await axios.post(`${REACT_APP_AUTH_SERVICE_ENDPOINT}/user/forgot-password`, { ...values });

    const { success, message } = data;

    try {

        notification(success ? 'success' : 'error', message);
        dispatch(onIsSendingOTP(false));

    } catch (error: any) {

        notification(success ? 'success' : 'error', error.message);
        dispatch(onIsSendingOTP(false));
        console.log(error.message);

    }
};

export const resetUserPassword = (values: resetPasswordFormTypes) => async (dispatch: AppDispatch) => {

    dispatch(onResetingPassword(true));

    const { password, otp } = values;

    const { data } = await axios.put(`${REACT_APP_AUTH_SERVICE_ENDPOINT}/user/reset-password/${otp}`, { password });

    const { success, message } = data;

    try {

        notification(success ? 'success' : 'error', message);
        dispatch(onResetingPassword(false));

    } catch (error: any) {

        notification(success ? 'success' : 'error', error.message);
        dispatch(onResetingPassword(false));
        console.log(error.message);

    }
};

export const changeUserPassword = (values: { password: string; }) => async (dispatch: AppDispatch, getState: RootState) => {

    const { token } = getState().authReducer;
    const { user } = getState().userReducer;
    const { _id } = user;

    try {

        axios.put(`${REACT_APP_AUTH_SERVICE_ENDPOINT}/user/${_id}/update-password`, {...values}, headers(token));
        
    } catch (error: any) {

        console.error(error);
        
    }
};