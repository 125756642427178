import { AppDispatch, RootState } from '..';
import { REACT_APP_COMPANY_ID, REACT_APP_MANAGE_SERVICE_ENDPOINT, headers } from '.';
import { getFleetsSuccess, onLoadingFleets } from '../reducers';

import axios from 'axios';

export const fetchFleets = () => async (dispatch: AppDispatch, getState: RootState) => {

    dispatch(onLoadingFleets(true));

    const { token } = getState().authReducer;

    const { data } = await axios.get(`${REACT_APP_MANAGE_SERVICE_ENDPOINT}/fleets/company/${REACT_APP_COMPANY_ID}`, headers(token));

    const { fleets } = data;

    try {
        
        dispatch(getFleetsSuccess(fleets));
        dispatch(onLoadingFleets(false))

    } catch (error: any) {
        console.error(error);
        dispatch(onLoadingFleets(false))
    }

};