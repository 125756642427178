import authReducer from './auth-reducer';
import { combineReducers } from 'redux';
import companyReducer from './company-reducer';
import deliveriesReducer from './deliveries-reducer';
import fleetReducer from './fleet-reducer';
import referalProfileReducer from './referal-profile-reducer';
import userReducer from './user-reducer';
import vehiclesReducer from './vehicle-reducer';

export * from './auth-reducer';
export * from './user-reducer';
export * from './referal-profile-reducer';
export * from './vehicle-reducer';
export * from './fleet-reducer';
export * from './company-reducer';
export * from './deliveries-reducer';

export const rootReducer = combineReducers({
    authReducer,
    userReducer,
    referalProfileReducer,
    vehiclesReducer,
    fleetReducer,
    companyReducer,
    deliveriesReducer
});